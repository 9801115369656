/* .project-section {
    min-height: 100vh;
} */

.project-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 150px;
    align-items: center;
}


@media screen and (max-width:425px) {
    .project-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        padding-top: 150px;
    }
}

@media screen and (max-width:768px) {
    .project-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        padding-top: 150px;
    }
}


.cocky-cocktails-image {
    width: auto;
    height: 20rem;
    border-radius: 20px;
}

.cocky-cocktails-image:hover {
    background-color: white;
    opacity: 0.9;
}

.cocky-cocktails-heading {
    align-self: center;
    margin: auto;
    padding-top: 50px;
    color: #F0F0F0;
}

.instruction-paragraph {
    align-self: center;
    padding-top: 5px;
    max-width: 825px;
}

.description-paragraph {
    align-self: center;
    padding-top: 30px;
    max-width: 825px;
    font-size: 20px;
    text-align: center;
}



@media screen and (max-width:425px) {
    .description-paragraph {
        font-size: 15px;
        padding: 15px;
    }
}

@media screen and (max-width:768px) {
    .description-paragraph {
        font-size: 15px;
        padding: 15px;
    }
}