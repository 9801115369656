.form-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 150px;
}

@media screen and (max-width:425px) {
        .form-container {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            padding-top: 150px;
        }
}

@media screen and (max-width:768px) {
    .form-container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding-top: 150px;
    }
}

.headline {
    align-self: center;
    color: #F0F0F0;
}

.paragraph {
    align-self: center;
}

@media screen and (max-width:425px) {
    .paragraph {
        font-size: 15px;
        max-width: fit-content;
        max-width: 200px;
    }    
}

.form-items {
    display: flex;
    flex-direction: column;
    max-width: 45%;
    margin: auto;
}

.name-input {
    border-radius: 5px;
    border-style: solid;
}

.email-input {
    border-radius: 5px;
    border-style: solid;
}

.message-input {
    height: 250px;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
}

@media screen and (max-width:425px) {
    .message-input {
        height: 100px;
    }
}

.button-container {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 25px;
}



