.footer-container {
    display: flex;
    background-color: black;
    justify-content: center;
    color: whitesmoke;
    margin: auto;
}
.footer-items {
    align-self: center;
    margin: auto;
    padding: 5px;
}