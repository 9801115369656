.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 950px;
  padding-top: 300px;
  margin: auto;
}

@media screen and (max-width:320px) {
  .home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-top: 300px; */
    margin: auto;
  }
}

@media screen and (max-width:425px) {
  .home-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin: auto;
  }  
}

@media screen and (max-width:768px) {
  .home-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin: auto;
  }  
}

@media screen and (max-width:1440px) {
  .home-container {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    margin: auto;
  }  
}

.name-title {
  font-size: 20px;
  color:#F0F0F0;
}

@media screen and (max-width:320px) {
  .name-title {
    font-size: 15px;
    padding-top: 50px;
  }
}

@media screen and (max-width:425px) {
  .name-title {
    align-items: center;
    padding-top: 50px;
  }
}

@media screen and (max-width:768px) {
  .name-title {
    align-items: center;
    padding-top: 50px;
  }
}



.job-title {
  font-size: 20px;
  margin-top: -10px;
}

@media screen and (max-width:320px) {
  .job-title {
    font-size: 15px;
  }
}

@media screen and (max-width:425px) {
  .job-title {
    font-size: 15px;
  }
}

@media screen and (max-width:768px) {
  .job-title {
    font-size: 15px;
  }
}

.greeting-heading {
    font-size: 64px;
  }

  @media screen and (max-width:320px) {
    .greeting-heading {
      font-size: 30px;
    }
  }

  @media screen and (max-width:425px) {
    .greeting-heading {
      font-size: 30px;
    }
  }

  @media screen and (max-width:768px) {
    .greeting-heading {
      font-size: 30px;
    }
  }

.social-button-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 200px;
}

@media screen and (max-width:320px) {
  .social-button-container {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

@media screen and (max-width:425px) {
  .social-button-container {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

@media screen and (max-width:768px) {
  .social-button-container {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

.github {
  width: 75px;
  height: auto;
  cursor: pointer;
  margin: auto;
  border: #F0F0F0;
  border-width: 5px;
}

.github:hover {
  width: 75px;
  height: auto;
  cursor: pointer;
  margin: auto;
  border-radius: 100%;
  border-style: double;

}

@media screen and (max-width:320px) {
  .github {
    width: 60px;
    height: auto;
    cursor: pointer;
  }
}

@media screen and (max-width:425px) {
  .github {
    width: 60px;
    height: auto;
    cursor: pointer;
  }
}

@media screen and (max-width:768px) {
  .github {
    width: 60px;
    height: auto;
    cursor: pointer;
  }
}

.linkedin {
  width: 75px;
  height: auto;
  cursor: pointer;
  margin: auto;
  border: #F0F0F0;
  border-width: 5px;
}

.linkedin:hover {
  width: 75px;
  height: auto;
  cursor: pointer;
  margin: auto;
  border-radius: 10%;
  border-style: double;
}

@media screen and (max-width:320px) {
  .linkedin {
    width: 60px;
    height: auto;
    cursor: pointer;
  }
}

@media screen and (max-width:425px) {
  .linkedin {
    width: 60px;
    height: auto;
    cursor: pointer;
  }
}

@media screen and (max-width:768px) {
  .linkedin {
    width: 60px;
    height: auto;
    cursor: pointer;
  }
}



