.App {
    font-family: 'Montserrat', sans-serif;
    background-color: #23262b;
    color: #F0F0F0;
}

.button {
    border-radius: 5px;
    background-color: whitesmoke;
    color: #23262b;
    padding: 5px 20px 5px 20px;
    cursor: pointer;
    font-weight: 900;
    font-size: larger;
  }

  .button:hover {
    background-color:#23262b;
    color: whitesmoke;
}